import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";


const Navbar = () => {

  const { logout } = useLogout()
  const { user } = useAuthContext()

  const handleClick = () => {
    logout()

  }
  return (
    <header>
        <div className='container'>
            <Link to="/">
                <h2>DAILY WORKOUT.in</h2>
            </Link>
            <nav>
              { user && (
              <div>
                <span>{user.email} </span>
                <button onClick={handleClick}>Log out</button>
              </div>
              )}
              { !user && (
              <div className="navLinks">
                <Link to="/login">Login</Link>
                <Link to="/signup">Sigu-Up</Link>
              </div>
              )}
            </nav>
        </div>  
    </header>
  )
}

export default Navbar
